<template>
  <v-app-bar v-if="!mobile" color="bg" class="nav" :height="64" elevation="0" absolute>
    <template #prepend>
      <RouterLink :to="{ name: 'Chat' }">
        <v-app-bar-title>
          <inline-svg :src="LogoIcon" aria-label="logo" />
        </v-app-bar-title>
      </RouterLink>
      <span v-if="isPro" style="color: grey; margin-bottom: 10px">Pro</span>
    </template>

    <template #default>
      <div v-if="organization" style="margin-top: 5px">
        <span style="color: grey; position: relative; top: -2px">/</span>
        <span style="margin-left: 10px">{{ organization.name }}</span>
      </div>
      <div v-if="!organization && isSandbox" style="margin-top: 5px">
        <span style="color: grey; position: relative; top: -2px">/</span>
        <span style="margin-left: 10px">Sandbox</span>
      </div>
      <div
        v-if="organizations && organizations.length && !organization && displayName"
        style="margin-top: 5px">
        <span style="color: grey; position: relative; top: -2px">/</span>
        <span style="margin-left: 10px">{{ displayName }}</span>
      </div>
      <OrganizationSwitcher />
    </template>

    <template #append>
      <div class="nav__right">
        <HeaderTabs :user="userProfile" />

        <div class="nav__controls">
          <BaseButton
            v-if="userProfile?.uid"
            :disabled="isUserLoading"
            to="/logout"
            color="text"
            variant="outlined"
            class="bg-bg">
            {{ t('global.logout') }}
          </BaseButton>
          <BaseButton
            v-if="!userProfile?.uid"
            :disabled="isUserLoading"
            class="login-btn"
            @click="onLoginClick"
            to="/login">
            {{ t('global.login') }}
          </BaseButton>
        </div>
      </div>
    </template>
  </v-app-bar>
  <MobileHeader v-else />
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import HeaderTabs from './components/HeaderTabs';
import OrganizationSwitcher from './components/OrganizationSwitcher.vue';
import BaseButton from '@/components/BaseButton.vue';
import LogoIcon from '@/assets/icons/logo-spear.svg';
import MobileHeader from '@/components/layout/MobileHeader.vue';

const { t } = useI18n();
const { mobile } = useDisplay();

const drawer = ref(false);

const store = useStore();
const router = useRouter();

const isPro = computed(() => store.getters.isPro);
const isSandbox = computed(() => store.getters.isSandbox);
const displayName = computed(() => store.getters.displayName);

const userProfile = computed(() => store.state.userProfile);
const isUserLoading = computed(() => store.state.isUserLoading);
const organization = computed(() => store.state.organization);
const organizations = computed(() => store.state.organizations);

const onLoginClick = () => {
  const pathContainsChatHistory = router.currentRoute.value.path.includes('/chatHistory/');
  if (pathContainsChatHistory) {
    localStorage.setItem('authRedirectedUrl', router.currentRoute.value.path);
  } else {
    localStorage.setItem('authRedirectedUrl', '/');
  }
};

watch(mobile, (newVal) => {
  if (!newVal) drawer.value = newVal;
});
</script>

<style lang="scss" scoped>
.p-menu-item {
  font-family: 'Red Hat Display', 'Nunito', 'Charter', Arial, sans-serif;
  font-weight: 600;
  color: rgb(var(--v-theme-primary));
  font-size: (14px);
}
.nav {
  padding: 0 rem(24px);
  border-bottom: 1px solid rgb(var(--v-theme-secondary));
  &__right {
    display: flex;
    align-items: center;
    text-transform: capitalize;
    gap: rem(12px);
    flex-grow: 1;
    justify-content: flex-end;
    :deep() {
      .v-btn {
        text-transform: capitalize;
      }
    }
  }
  &__controls {
    display: flex;
    align-items: center;
    gap: rem(5px);
    .login-btn {
      margin-left: rem(144px);
    }
  }
}
:deep() {
  .v-toolbar-title {
    &__placeholder {
      display: flex;
    }
  }
  .v-toolbar {
    &__content {
      gap: rem(12px);
    }
    &__prepend {
      margin: 0;
    }
    &__append {
      margin-right: 0;
      flex-grow: 1;
      justify-content: flex-end;
    }
  }
}
.unfold-btn {
  text-transform: none;
}
</style>
