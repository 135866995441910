<template>
  <v-dialog
    class="modal"
    :class="{
      'modal--mobile': mobile,
    }"
    :width="mobile ? '95%' : '80%'"
    :max-height="mobile ? '95%' : '75%'"
    max-width="857"
    v-model="model">
    <div class="modal-inner bg-bg">
      <div v-if="title || hasSlot('title')" class="modal__header">
        <h2 class="modal__header-title">
          {{ title }}
          <slot v-if="!title" name="title" />
        </h2>
      </div>
      <CloseButton
        v-if="title"
        class="close-btn"
        :class="{
          'close-btn--float': floatCloseBtn,
        }"
        @click="emit('update:modelValue', false)" />
      <div class="modal__content">
        <div class="modal__content-inner">
          <CloseButton
            v-if="!title"
            class="close-btn"
            :class="{
              'close-btn--float': floatCloseBtn,
            }"
            @click="emit('update:modelValue', false)" />
          <slot />
        </div>
      </div>
      <div v-if="hasSlot('footer')" class="modal__footer">
        <slot name="footer" />
      </div>
    </div>
  </v-dialog>
</template>

<script setup>
import { computed, toRefs } from 'vue';
import { useDisplay } from 'vuetify';
import { hasSlot } from '@/composables';
import CloseButton from '@/components/CloseButton.vue';

const { mobile } = useDisplay();

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
  floatCloseBtn: {
    type: Boolean,
    default: false,
  },
});

const { modelValue } = toRefs(props);

const model = computed({
  get: () => modelValue.value,
  set: (val) => emit('update:modelValue', val),
});
</script>

<style lang="scss" scoped>
.modal {
  .modal-inner {
    border-radius: rem(16px);
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    & > * {
      &:not(:last-child) {
        border-bottom: 1px solid $color-secondary;
      }
    }
  }
  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: rem(12px);
    padding-right: rem(55px) !important;
  }
  .close-btn {
    border: none !important;
    position: absolute;
    top: rem(22px);
    right: rem(22px);
    &--float {
      top: rem(-8px) !important;
      right: rem(-8px) !important;
      position: relative;
      float: right;
    }
  }
  &__content {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: rem(14px);
  }
  &__content-inner {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: rem(10px);
  }
  &__header,
  &__footer {
    flex-shrink: 0;
    padding: rem(24px);
  }
  &__footer {
    position: relative;
  }
  &--mobile & {
    &__header-title {
      @include text18;
    }
    &__content {
      padding: rem(6px);
    }
    &__header,
    &__footer {
      padding: rem(16px);
    }
  }
  &--mobile {
    .close-btn {
      top: rem(14px);
      right: rem(14px);
      &--float {
        top: rem(-2px) !important;
        right: rem(-2px) !important;
      }
    }
  }
}
</style>
