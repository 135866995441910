<template>
  <v-tabs
    light
    class="header-tabs"
    :class="{
      'header-tabs--compact': !mdAndUp,
    }"
    color="primary"
    fixed-tabs
    selected-class="header-tab--selected"
    :mandatory="false"
    v-model="tab">
    <template v-if="!mobile">
      <HeaderTab
        v-for="(item, key) in tabs"
        :key="key"
        class="header-tab"
        :class="{
          'header-tab--last-main': key === tabs.length - 1,
        }"
        :to="item.to"
        :exact="!!item.exact"
        :icon="item.icon"
        :active="tab === key">
        {{ item.label }}
      </HeaderTab>
    </template>
    <HeaderTab
      v-if="user?.uid"
      to="/userprofile"
      class="header-tab header-tab--settings"
      :active="currentRouteName === 'UserProfile'"
      :disabled="currentRouteName === 'UserProfile'"
      custom>
      <SettingsButton :active="currentRouteName === 'UserProfile'" class="settings-btn" />
    </HeaderTab>
  </v-tabs>
</template>

<script setup>
import { computed, toRefs, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import { useRouter } from 'vue-router';
import HeaderTab from './HeaderTab';
import SettingsButton from '@/components/SettingsButton.vue';

const router = useRouter();
const { t } = useI18n();
const { mobile, mdAndUp } = useDisplay();

const tab = ref(null);

const props = defineProps({
  user: {
    type: Object,
    default: null,
  },
});

const { user } = toRefs(props);

const store = useStore();
const hidePricing = computed(() => store.getters.isSandbox || store.state.organization);
const currentRouteName = computed(() => router.currentRoute.value.name);

const tabs = computed(() => {
  const defaultTabs = [
    {
      label: t('global.chat'),
      to: {
        name: 'Chat',
      },
      exact: true,
    },
    {
      label: t('global.search'),
      to: {
        name: 'Search',
      },
    },
    {
      label: t('global.pricing'),
      to: {
        name: 'Pricing',
      },
    },
    {
      label: t('global.about'),
      to: {
        name: 'About',
      },
    },
    {
      label: t('global.knowledgeBase'),
      to: {
        name: 'Knowledge Base',
      },
    },
  ];

  if (hidePricing.value) {
    return defaultTabs.filter((tb) => tb.label !== t('global.pricing'));
  }

  return defaultTabs;
});
</script>

<style lang="scss" scoped>
.header-tabs {
  flex-shrink: 1;
  .settings-tab {
    .settings-btn {
      pointer-events: none;
    }
  }
  .header-tab {
    &--settings {
      margin-left: rem(56px);
      padding: 0 rem(5px);
      :deep() {
        & > .v-btn__overlay {
          display: none;
        }
      }
    }
  }
  :deep() {
    .v-slide-group__content {
      gap: rem(32px);
    }
  }
  &--compact {
    .header-tab {
      &--settings {
        margin-left: rem(16px);
      }
    }
    :deep() {
      .v-slide-group__content {
        gap: rem(8px);
      }
    }
  }
}
</style>
