import { createApp } from 'vue';
import VueClipboard from 'v-clipboard';
import { createMetaManager, plugin as metaPlugin } from 'vue-meta'; // for Option API
import VueGtag from 'vue-gtag';
import { createGtm } from '@gtm-support/vue-gtm';
import InlineSvg from 'vue-inline-svg';
import * as Sentry from '@sentry/vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { config } from './store/modules/config';
import { auth } from './lib/firebase';
import appearAnimation from './directives/appearAnimation';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n-setup';
import PageMain from '@/components/layout/PageMain.vue';
import PageHeader from '@/components/layout/PageHeader.vue';
import PageSection from '@/components/layout/PageSection.vue';
import SectionHeader from '@/components/layout/SectionHeader.vue';
import SectionContent from '@/components/layout/SectionContent.vue';
import SectionColumns from '@/components/layout/SectionColumns.vue';
import MobilePageMain from '@/components/layout/MobilePageMain.vue';
import ContentCard from '@/components/layout/ContentCard.vue';
import Loader from '@/components/Loader.vue';
import LoopLoader from '@/components/LoopLoader.vue';
import SearchLoader from '@/components/SearchLoader.vue';
import CircleLoader from '@/components/CircleLoader.vue';
import { getRouteName } from '@/utils';

import '@/assets/css/global.css';
import '@/styles/main.scss';

const app = createApp(App);
const VUE_APP_ENV = process.env.VUE_APP_ENV;
if (VUE_APP_ENV === 'production' || VUE_APP_ENV === 'beta' || VUE_APP_ENV === 'dev') {
  Sentry.init({
    app,
    environment: VUE_APP_ENV,
    dsn: 'https://e7fffbf34070d92b00a4cdf372236557@o4506999730339840.ingest.us.sentry.io/4506999897653248',
    tunnel: config.apiBaseUrl + '/sentry-tunnel',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    maxUrlLength: 10000,
    beforeSend(event, hint) {
      const error = hint.originalException;
      // Don't burn through our Monthly 50k issues with errors that are not actionable.
      if (error && error.message && error.message.match(/Unterminated string in JSON/i)) {
        return null;
      } else {
        return event;
      }
    },
    ignoreErrors: [/Unterminated string in JSON/i],
    // Performance Monitoring.
    tracesSampleRate: 0.1, //  Capture 10% of the transactions. 1.0 == 100%
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/api-cloud-run/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
if (process.env.NODE_ENV === 'development' || process.env.VUE_APP_ENV === 'localhost') {
  app.config.devtools = true;
}
app.component('PageMain', PageMain);
app.component('PageHeader', PageHeader);
app.component('PageSection', PageSection);
app.component('SectionHeader', SectionHeader);
app.component('SectionContent', SectionContent);
app.component('SectionColumns', SectionColumns);
app.component('MobilePageMain', MobilePageMain);
app.component('ContentCard', ContentCard);
app.component('Loader', Loader);
app.component('LoopLoader', LoopLoader);
app.component('CircleLoader', CircleLoader);
app.component('SearchLoader', SearchLoader);
app.component('InlineSvg', InlineSvg);

app.directive('appear', appearAnimation);

app.use(store);
app.use(router);
app.use(vuetify);
app.use(i18n);
app.use(VueClipboard);
const metaManager = createMetaManager();
app.use(metaManager);
app.use(metaPlugin);
app.use(
  VueGtag,
  {
    appName: 'RCTAlert',
    pageTrackerScreenviewEnabled: true,
    config: { id: 'G-1Y42JSKDRP' }, // G-H1BHW0WF7J
    pageTrackerTemplate(to) {
      return {
        page_path: to.path,
        page_title: to.meta.title || 'EvidenceHunt',
        page_location: window.location.href,
      };
    },
  },
  router
);
const gtmInstance = createGtm({
  id: 'GTM-WTSHKK2H',
  vueRouter: router,
  respectDoNotTrack: false,
});

app.use(gtmInstance);

app.config.globalProperties.$store = store;

store.watch(
  (state) => state.locale,
  (newLocale) => {
    i18n.global.locale.value = newLocale;
  }
);

auth.onAuthStateChanged((user) => {
  if (user) {
    store.dispatch('fetchUserProfile', user);
  }
});

router.beforeEach((to, from, next) => {
  if (to.name === 'Auth Action') {
    if (to.query.mode === 'resetPassword') {
      router.push({
        name: 'Reset',
        query: {
          oobCode: to.query.oobCode,
        },
      });
    }
  } else {
    next();
  }
});
router.afterEach((to) => {
  // Track page views
  const routeName = getRouteName(to, true);
  store.dispatch('trackEvent', {
    event: 'PageViewed',
    values: {
      page: routeName,
    },
  });

  store.commit('SET_STATE_PROPERTY', {
    property: 'sideBanner',
    value: !!to.meta.sideBanner,
  });
});

app.mount('#app');
