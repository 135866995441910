<template>
  <v-app
    class="app text-text"
    :class="{
      'app--mobile': mobile,
    }">
    <metainfo />
    <Header v-if="!customHeader" />
    <div class="content">
      <v-fade-transition mode="out-in" hide-on-leave>
        <SideBanner v-if="sideBanner && !mobile" class="sidebanner" />
      </v-fade-transition>
      <router-view v-slot="{ Component }">
        <v-fade-transition hide-on-leave>
          <component :is="Component" />
        </v-fade-transition>
      </router-view>
    </div>
    <Footer v-if="!mobile" />
    <PolicyPopup />
    <ContactModal />
    <EnterpriseContactModal />
  </v-app>
</template>

<script>
import { mapState, mapMutations, useStore } from 'vuex';
import PolicyPopup from './components/PolicyPopup.vue';
import SideBanner from './features/SideBanner.vue';
import Header from '@/features/Header';
import Footer from '@/features/Footer';
import ContactModal from '@/features/ContactModal.vue';
import EnterpriseContactModal from '@/features/EnterpriseContactModal.vue';

export default {
  name: 'App',
  metaInfo: {
    title: 'EvidenceHunt - Search for medical scientific evidence',
    htmlAttrs: {
      lang: 'en',
    },
    meta: [
      { charset: 'utf-8' },
      {
        name: 'description',
        content:
          'Whether you are looking for clinical trials in oncology, the newest evidence in certain disease areas, or whether you want to identify clinical evidence on a specific drug, EvidenceHunt has it. Rather than using Pubmed and searching through many thousands of articles, EvidenceHunt facilitates clinical evidence at your fingertips using artificial intelligence.',
      },
      {
        name: 'keywords',
        content:
          'find evidence, chatGPT for healthcare, AI for new trials, find new trials, new treatment, medical, scientific evidence, clinical trials, oncology, disease areas, clinical evidence, specific drug, EvidenceHunt, Pubmed, articles, artificial intelligence',
      },
    ],
  },

  data: () => ({
    //
  }),
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Header,
    PolicyPopup,
    // eslint-disable-next-line vue/no-reserved-component-names
    Footer,
    SideBanner,
    ContactModal,
    EnterpriseContactModal,
  },
  computed: {
    ...mapState(['customHeader', 'sideBanner']),
    mobile() {
      return this.$vuetify.display.mobile;
    },
  },
  watch: {
    mobile(newVal) {
      this.SET_STATE_PROPERTY({ property: 'mobile', value: newVal });
    },
  },
  mounted() {
    const store = useStore();

    // Check environment and initialize Paddle
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-undef
      Paddle.Environment.set('sandbox');
    }

    // eslint-disable-next-line no-undef
    Paddle.Initialize({
      token:
        process.env.NODE_ENV === 'production'
          ? 'live_d5f0bed9bc2d7f99bb3fe3d1ac6'
          : 'test_3255ba82dd7dd2eee05e8e31464',
      eventCallback(data) {
        store.dispatch('trackEvent', { event: data.name });
        if (data.name === 'checkout.completed') {
          localStorage.setItem('paddlePaymentInProgress', true);
        }
      },
    });

    this.SET_STATE_PROPERTY({ property: 'mobile', value: this.mobile });
  },
  methods: {
    ...mapMutations(['SET_STATE_PROPERTY']),
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600&display=swap');
</style>

<style lang="scss" scoped>
.content {
  flex-grow: 1;
  display: flex;
  align-items: stretch;
}
</style>
